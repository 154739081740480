import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { position } from 'styled-system'
import App from 'App'
import { space, colors } from 'theme'
import { Box, Flex } from 'components/Layout'
import { H1, H3, Text } from 'components/Typography'
import Header from 'components/Header'
import ellipsisTopRight from 'static/svg/shape1.svg'
import ellipsisBottomLeft from 'static/svg/shape2.svg'
import facebookIcon from 'static/svg/facebook_icon.svg'
import linkedinIcon from 'static/svg/linkedin_icon.svg'
import twitterIcon from 'static/svg/twitter_icon.svg'

const StyledText = styled(Text)`
  opacity: 0.8;
`

const EllipsisShape = styled.img`
  margin-top: auto;
  position: absolute;
  max-width: 100%;
  height: auto;
  z-index: -1;
  ${position};
`

const StyledBox = styled(Box)`
  background: url(${ellipsisBottomLeft}) no-repeat;
  background-position: left bottom;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
`

const StyledImg = styled.img`
  width: 35px;
`

const SocialNetworkLink = styled.a`
  padding-left: ${space.m};
  padding-right: ${space.m};
  color: initial;
  text-decoration: none;
  &:visited {
    color: initial;
  }
`

const StyledH3 = styled(H3)`
  opacity: 0.8;
`

const ThankYou = () => {
  return (
    <React.Fragment>
      <App isHeaderVisible={true}>
        <StyledBox>
          <EllipsisShape src={ellipsisTopRight} top="0" right="0" />
          <Flex minHeight="100vh" mt="xl" px="m" flexDirection="column" alignItems="center">
            <H1 textAlign="center">Thank you!</H1>
            <Box mt="m" maxWidth="600px" textAlign="center">
              <StyledH3 color="secondaryText">
                You're officially on the list. We’ll begin sending you weekly insights soon.
              </StyledH3>
            </Box>
            <StyledText mt="m" fontSize="l" color="secondaryText" textAlign="center">
              Share Agency Vista
            </StyledText>
            <Flex my="l" flexWrap="wrap">
              <SocialNetworkLink href="https://twitter.com/AgencyVista">
                <StyledImg src={twitterIcon} />
              </SocialNetworkLink>
              <SocialNetworkLink href="https://www.linkedin.com/company/agencyvista/">
                <StyledImg src={linkedinIcon} />
              </SocialNetworkLink>
              <SocialNetworkLink href="https://www.facebook.com/AgencyVistaOfficial">
                <StyledImg src={facebookIcon} />
              </SocialNetworkLink>
            </Flex>
            <StyledLink to="/">Back to homepage</StyledLink>
          </Flex>
        </StyledBox>
      </App>
    </React.Fragment>
  )
}

export default ThankYou
